@import '../../app/styles/media-queries';
.project-item {
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: auto;
	grid-gap: 24px;
	padding: 24px;
	background: #ffffff;
	border: 2px solid #000000;
	border-radius: 10px;
	@include ma(tablet) {
		max-width: 322px;
		justify-self: center;
	}
	&__img {
		width: 100%;
		height: auto;
		object-fit: cover;
		border-radius: 5px;
		overflow: hidden;
	}

	&__description {
	}

	&__name {
		font-family: 'Dela Gothic One';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 120%;
		color: #191919;
		padding: 0 0 24px 0;
	}

	&__deteils-list {
	}

	&__details-item {
		font-family: 'Circe';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 120%;
		padding: 5px 0;
		color: #191919;
		&:not(:first-child, :last-child) {
			padding: 5px 0;
		}
		&:first-child {
			padding: 5px 0 0 0;
		}

		&:last-child {
			padding: 0 0 5px 0;
		}
	}

	&__btn-box {
		align-self: end;
		display: flex;
		justify-content: flex-end;
	}

	&__btn {
		display: flex;
		justify-content: center;
		align-items: center;
		background: transparent;
	}

	&__btn-title {
		font-family: 'Dela Gothic One';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 130%;
		text-transform: uppercase;
		color: #191919;
		padding: 0 12px 0 0;
	}

	&__btn-icon {
		width: 40px;
		object-fit: cover;
	}
}
