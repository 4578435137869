@import '../../app/styles/media-queries';
.project-wrap {
	padding-bottom: 100px;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.decor__bottom {
	position: absolute;
	width: 539px;
	object-fit: cover;
	right: 0;
	bottom: 500px;
	@include ma(bitTablet) {
		display: none;
	}
}
