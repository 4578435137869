@import '../../app/styles/media-queries';
.projects {
	position: relative;
	z-index: 10;
	&__header {
		font-family: 'Dela Gothic One';
		font-style: normal;
		font-weight: 400;
		font-size: 60px;
		line-height: 120%;
		color: #191919;
		text-align: center;
		padding: 0 0 64px 0;
	}

	&__list {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-gap: 64px 30px;
		@include ma(bigTablet) {
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(3, 1fr);
		}
		@include ma(tablet) {
			grid-template-columns: repeat(1, 1fr);
			grid-template-rows: repeat(6, 1fr);
			grid-gap: 32px 15px;
		}
	}
}
