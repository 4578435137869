@import '../../app/styles/media-queries';

.footer {
	padding: 31px 0;

	border-top: 1px solid #69644fad;
	&__container {
		display: flex;
		justify-content: space-between;
		@include ma(bigMobile) {
			flex-direction: column;
			align-items: center;
		}
	}

	&__text {
		font-family: 'Circe';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 130%;
		color: #4e462c;
	}
}
