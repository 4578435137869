@import '../../app/styles/media-queries';
.logo {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 140px;
	position: absolute;
	left: 15px;
	&__img {
	}
	@include ma(bigTablet) {
		position: static;
	}
}

.menu-list {
	display: flex;
	align-items: center;
	@include ma(mobile) {
		flex-direction: column;
	}

	&__item {
		&:not(:last-child) {
			margin-right: 10px;

			@include ma(tablet) {
				margin-right: 0;
				margin-bottom: 0.3125rem;
			}
		}

		@include ma(tablet) {
			width: 100%;
		}
	}

	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'Circe';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 27px;
		color: #28282b;
		padding: 2px 20px;
		border: 2px solid #191919;
		border-radius: 100px;
		border-radius: 11.875rem;
		transition: background-color 180ms ease-in-out, color 180ms ease-in-out;
		background: #ffffff;
		@include ma(mobile) {
			width: 100%;
			padding: 0.625rem 0.9375rem;
		}

		&:hover,
		&:focus-within {
			background-color: rgba(255, 255, 255, 0);
			color: #707070;
		}
	}
}

.burger-btn {
	display: block;
	width: 2.8125rem;
	height: 2.8125rem;

	&--active {
		position: absolute;
		top: 20px;
		right: 20px;
		.burger-btn {
			&__inner {
				background-color: #000;
			}
			&__item {
				background-color: #c3c3c3;

				&:nth-child(1),
				&:nth-child(2) {
					transform: translate(-50%, -50%) rotate(45deg);
				}
				&:nth-child(3) {
					transform: translate(-50%, -50%) rotate(-45deg);
				}
			}
		}
	}

	&__inner {
		display: block;
		width: 2.8125rem;
		height: 2.8125rem;
		position: relative;
		background-color: #fff;
		border-radius: 0.1875rem;
		transition: background-color 180ms ease-in-out;
	}

	&__item {
		width: 70%;
		height: 0.125rem;
		background-color: #000;
		position: absolute;
		top: 50%;
		left: 50%;
		transition: transform 180ms ease-in-out, background-color 180ms ease-in-out;

		&:nth-child(1) {
			transform: translate(-50%, calc(-50% + 0.625rem));
		}
		&:nth-child(2) {
			transform: translate(-50%, -50%);
		}
		&:nth-child(3) {
			transform: translate(-50%, calc(-50% - 0.625rem));
		}
	}
}

.nav {
	display: flex;
	align-items: center;

	@include ma(tablet) {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.5);
		flex-direction: column;
		align-items: flex-end;
		pointer-events: none;
		opacity: 0;
		transform: translateX(100%);
		transition: transform 180ms ease-in-out, opacity 180ms ease-in-out;
	}

	&--active {
		@include ma(tablet) {
			pointer-events: all;
			opacity: 1;
			transform: translateX(0);
		}
	}

	&__menu-list {
		@include ma(tablet) {
			height: 100%;
			width: 80%;
			flex-direction: column;
			align-items: flex-end;
			pointer-events: none;
			background-color: #fff;
			padding-top: 5.75rem;
			padding-left: 0.3125rem;
			padding-right: 0.3125rem;
		}
	}
}

.header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 999;
	&__inner {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 31px 15px;
		@include ma(bigTablet) {
			justify-content: space-between;
		}

		@include ma(tablet) {
			padding: 0.75rem 0;
			justify-content: center;
		}

		@include ma(mobile) {
			flex-direction: column;
			justify-content: flex-start;
		}
	}

	&__logo {
		@include ma(mobile) {
			margin-bottom: 0.625rem;
		}
	}

	&__nav {
	}

	&__burger-btn {
		display: none;

		@include ma(tablet) {
			display: block;
			position: fixed;
			top: 0.625rem;
			right: 0.625rem;
		}
	}

	&__container {
		position: relative;
	}
}
