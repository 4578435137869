@import '../../../app/styles/media-queries';

.project {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-auto-rows: auto;
	grid-gap: 64px;
	padding: 42px;
	background: #fff;
	border: 2px solid #000000;
	border-radius: 10px;
	position: relative;
	z-index: 10;

	&__name {
		font-family: 'Dela Gothic One';
		font-style: normal;
		font-weight: 400;
		font-size: 60px;
		line-height: 120%;
		color: #191919;
		@include ma(bigMobile) {
			font-size: 30px;
		}
	}

	&__text-content {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-auto-rows: auto;
		grid-gap: 42px;
		position: relative;
		@include ma(bigMobile) {
			grid-gap: 15px;
		}
	}

	&__subheading {
		font-family: 'Dela Gothic One';
		font-style: normal;
		font-weight: 400;
		font-size: 40px;
		line-height: 120%;
		color: #191919;
		@include ma(bigMobile) {
			font-size: 24px;
		}
	}

	&__list {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 24px;
	}

	&__list-item {
		font-family: 'Circe';
		font-style: normal;
		font-weight: 400;
		font-size: 24px;
		line-height: 120%;
		/* or 29px */

		color: #191919;
		@include ma(bigMobile) {
			font-size: 16px;
		}
	}

	&__paragraph {
		font-family: 'Circe';
		font-style: normal;
		font-weight: 400;
		font-size: 24px;
		line-height: 120%;
		color: #191919;
		@include ma(bigMobile) {
			font-size: 16px;
		}
	}

	&__img-box {
		width: 100%;
		overflow-x: auto;
		z-index: 0;
		// &::-webkit-scrollbar {
		// 	display: none;
		// }
	}

	&__img {
		object-fit: cover;

		&--table-1 {
			width: 1073px;
			height: 170px;
		}
		&--table-2 {
			width: 651px;
			height: 214px;
		}
	}

	&__btn-box {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	&__btn {
		background: #fed426;
		border: 2px solid #191919;
		border-radius: 10px;
		padding: 10px 45px;
		font-family: 'Circe';
		font-style: normal;
		font-weight: 400;
		font-size: 24px;
		line-height: 35px;
		transition: 0.3s;
		&:hover {
			background-color: rgba(255, 255, 255, 0);
			color: #707070;
		}
	}
	@include ma(bigMobile) {
		padding: 15px;
		grid-gap: 30px;
	}
}
.highlight {
	font-weight: 800;
}
