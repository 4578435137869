@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative&family=Faster+One&family=Work+Sans&display=swap');

@font-face {
	font-family: 'circe';
	src: url('../fonts/circe-bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'circe';
	src: url('../fonts/circe-extrabold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Dela Gothic One';
	src: url('../fonts/DelaGothicOne-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	-webkit-tap-highlight-color: transparent;
}

body {
	font-family: '';
	font-weight: 400;
	color: #000;
	font-family: 'circe';
}

button,
input,
a,
ul,
li,
ol,
textarea {
	border: none;
	color: inherit;
	list-style-type: none;
	text-decoration: none;
	outline: none;
	font-family: inherit;
}

button {
	display: inline-block;
	cursor: pointer;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
	opacity: 0;
}

img {
	display: block;
	height: auto;
}

.link {
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 400;
	margin: 0;
	line-height: 1.35;
	font-family: 'Poppins', sans-serif;
}

@media (min-width: 1170px) {
	html {
		font-size: 1.1458333333vw;
	}
}

@media (max-width: 720px) {
	.grid-12 {
		grid-gap: 0 15px;
	}
}
