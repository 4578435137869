.project {
	&__swiper {
		width: 100%;
	}
	&__swiper-slide {
		border-radius: 10px;
		background: #fed426;
	}

	&__swiper-slide-img {
		width: 100%;
		object-fit: cover;
	}
	&__swiper-arrow {
		position: absolute;
		background: #ffffff;
		border: 2px solid #000000;
		border-radius: 10px;
		padding: 10px;
		top: 50%;
		z-index: 999;
		&--left {
			left: 0;
			transform: translate(-50%, -50%);
		}

		&--right {
			right: 0;
			transform: translate(50%, -50%);
		}
	}
}
