@mixin ma($breakpoint) {
	@if $breakpoint == pc {
		@media only screen and (max-width: 1770px) {
			@content;
		}
	}
	@if $breakpoint == laptop {
		@media only screen and (max-width: 1412px) {
			@content;
		}
	}
	@if $breakpoint == smallLaptop {
		@media only screen and (max-width: 1199px) {
			@content;
		}
	}
	@if $breakpoint == bigTablet {
		@media only screen and (max-width: 1024px) {
			@content;
		}
	}
	@if $breakpoint == tablet {
		@media only screen and (max-width: 820px) {
			@content;
		}
	}

	@if $breakpoint == bigMobile {
		@media only screen and (max-width: 540px) {
			@content;
		}
	}

	@if $breakpoint == mobile {
		@media only screen and (max-width: 425px) {
			@content;
		}
	}
}
