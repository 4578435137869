@import '../../app/styles/media-queries';
.main {
	position: relative;
	overflow: hidden;
	padding: 0 0 100px;
}

.decor {
	position: absolute;
	z-index: 0;
	&--top {
		width: 401px;
		height: 531px;
		left: -105px;
		top: -48px;
		@include ma(pc) {
			display: none;
		}
	}

	&--middle {
		width: 524.31px;
		right: 0px;
		top: 429.52px;
	}

	&--bottom {
		width: 378px;
		height: 562px;
		left: -53px;
		bottom: -223px;
	}
	@include ma(smallLaptop) {
		display: none;
	}
}
