.progress {
	&__heading {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 0 5px 0;
	}

	&__collected {
		font-family: 'Circe';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 120%;
		padding: 5px 0;
		color: #191919;
	}

	&__bar {
		width: 100%;
		background: #191919;
		height: 10px;
		border-radius: 15px;
		overflow: hidden;
	}

	&__bar-line {
		width: 20%;
		background: var(--color-primary);
		height: 100%;
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		padding: 5px 0 0 0;
	}

	&__percent {
		font-family: 'Circe';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 120%;
		padding: 5px 0;
		color: #191919;
	}

	&__days-left {
		font-family: 'Circe';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 120%;
		padding: 5px 0;
		color: #191919;
	}

	&__investors {
		font-family: 'Circe';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 120%;
		padding: 5px 0;
		color: #191919;
	}
}
